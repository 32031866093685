import { AngularEditorConfig } from "@kolkov/angular-editor";
import { Injectable } from '@angular/core';

@Injectable()
export class EditorConfigService {

    getOption(): AngularEditorConfig {
        return {
            editable: false,
            spellcheck: false,
            height: 'auto',
            minHeight: '20rem',
            maxHeight: 'auto',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: 'Enter text here...',
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: [
                { class: 'arial', name: 'Arial' },
                { class: 'times-new-roman', name: 'Times New Roman' },
                { class: 'calibri', name: 'Calibri' },
                { class: 'comic-sans-ms', name: 'Comic Sans MS' }
            ],
            customClasses: [
                {
                    name: 'center',
                    class: 'text-center',
                },
                {
                    name: 'left',
                    class: 'text-left'
                },
                {
                    name: 'right',
                    class: 'text-right'
                },
            ],
            uploadUrl: '/api/media-files/single',
            sanitize: false,
            toolbarPosition: 'top',
            toolbarHiddenButtons: [
                ['fontSize']
            ]
        };
    }
}